import {  useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Button,
  Checkbox,
  Stack,
  TextField,
} from "@mui/material";
// import data2 from "../../data2.json";
import { useDispatch } from "react-redux";
import {
  getAllProduct,
  searcName,
} from "../../redux/productSlice/productSlice";

export default function SearchArea() {
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [notesChecked, setNotesChecked] = useState(false);
  const dispatch = useDispatch();

  const handleSearch = () => {
    if (name) dispatch(searcName({ query: "name", data: name }));
    else if (code) dispatch(searcName({ query: "productCode", data: code }));
    else if (supplier)
      dispatch(searcName({ query: "supplier", data: supplier }));
    else if(notesChecked){
      dispatch(searcName({ query: "not", data: notesChecked }));
    }
    else dispatch(getAllProduct(1));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Ürün Adı :
            </div>
            <div style={{ width: "100%" }}>
              <Stack spacing={1}>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Stok Kodu :
            </div>
            <div style={{ width: "100%" }}>
              <Stack spacing={1}>
                <TextField
                  onChange={(e) => setCode(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Kategori :
            </div>
            <div style={{ width: "100%" }}>
              <TextField fullWidth size="small" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Tedarikçi :
            </div>
            <div style={{ width: "100%" }}>
              <Stack spacing={1}>
                <TextField
                  onChange={(e) => setSupplier(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid sx={{ marginY: 2 }} container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className="d-flex align-items-center">
            <div className="me-2 text-center">Notlar :</div>
            <div>
              <Checkbox
              checked={notesChecked}
              onChange={(e) => setNotesChecked(e.target.checked)}
              inputProps={{ "aria-label": "Checkbox demo" }}
               />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            sx={{ backgroundColor: "#1565C0" }}
            fullWidth
            size="large"
            variant="contained"
            onClick={() => handleSearch()}
          >
            Ara
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
