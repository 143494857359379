import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
import { getPageOrders, setOtherPageNum } from '../../redux/odersSlice/ordersSlice';



export default function OrderPagination() {
  const dispatch = useDispatch();
    const page = useSelector(state => state.order.pagination)
    const otherPage = useSelector(state => state.order.otherPageNum)


  const handleChange = (event, value) => {
    event.preventDefault()
 //   dispatch(setPageNum(value)); // Tıklanan sayfanın değerini state'e kaydedin
    dispatch(getPageOrders(value));
    dispatch(setOtherPageNum(value));
    console.log("otherPage : ", otherPage)
  };

  return (
    <Stack spacing={2}>
      <Pagination
        page={otherPage === null ? page : otherPage}// Tıklanan sayfa aktifse, selected özelliğini true olarak ayarlayın
        count={page}
        boundaryCount={1}  // Baş ve son tarafında gösterilecek sayfa sayısı (örneğin 1 ... 200)
        siblingCount={8}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}