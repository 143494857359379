import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

const OrderDetailTable = ({ orderProduct }) => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="order detail table" sx={{ bgcolor: "#DDE1E5" }}>
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: "bold" }}>Resim</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Kod/Ürün</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Adet</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>B.Fiyatı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderProduct.map((ord, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  <img
                    src={ord.picture}
                    style={{ width: "80px", height: "100px" }}
                    alt={ord.name}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <span style={{ fontWeight: "bold", color: "blue" }}>
                    <Link to={`/product/${ord.model}`}>{ord.model}</Link>
                  </span>
                  /{ord.name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontSize: "25px", color: "red" }}
                >
                  {ord.quantity}
                </TableCell>
                <TableCell component="th" scope="row">
                  {ord.price}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderDetailTable;
