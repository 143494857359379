import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TbMenu2 } from "react-icons/tb";
import { howManyUnreadMessage } from "../redux/N11Slice/n11Slice";

const Topbar = ({ setIsLogin }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchorEl, notificationSetAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const notificationOpen = Boolean(notificationAnchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {unreadMessage} = useSelector((state) => state.n11);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickNotification = (event) => {
    notificationSetAnchorEl(event.currentTarget);
    // dispatch(howManyUnreadMessage());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotification = () => {
    notificationSetAnchorEl(null);
    dispatch(howManyUnreadMessage());
  };

  const handleLogout = () => {
    document.cookie = `apiResponse=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    setIsLogin(false);
    navigate("/");
    setAnchorEl(null);
    localStorage.removeItem("me");
  };

  React.useEffect(() => {
    dispatch(howManyUnreadMessage());
  }, [dispatch]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      sx={{ marginLeft: 1 }}
    >
      {/* Searc Bar */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <IconButton type="button" sx={{ p: 1 }}>
          <TbMenu2 />
        </IconButton>
      </Box>

      {/* Icons */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton
          id="notification-button"
          aria-controls={notificationOpen ? "notification-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={notificationOpen ? "true" : undefined}
          onClick={handleClickNotification}
        >
          <Badge badgeContent={unreadMessage.count} color="primary">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <Menu
          id="notification-menu"
          anchorEl={notificationAnchorEl}
          open={notificationOpen}
          onClose={handleCloseNotification}
          MenuListProps={{
            "aria-labelledby": "notification-button",
          }}
        >
          <MenuItem onClick={handleCloseNotification}>
            <Link style={{color : "black", textDecoration : 'none'}} to={"/message"}>
              {unreadMessage?.count} Okunmamış Mesaj Bulunmaktadır
              </Link>
            </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
