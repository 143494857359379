import OrderSearch from "../../component/Orders/OrderSearch";
import OrderTable from "../../component/Orders/OrderTable";

const { Container } = require("@mui/material")

const Orders = () => {
    return (
        <Container fixed>
                <h3 style={{ textDecoration: "underline" }}>Siparişler</h3>
            <OrderSearch/>
            <OrderTable/>
        </Container>
    )
}

export default Orders;