import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import slugify from "slugify";
import { Link } from "react-router-dom";

export default function PacketProductTable({ singlePro }) {
  const getProductSalesPrice = (productCode) => {
    // productCode'u "-" karakterine göre bölüyoruz
    let pazaryeri = productCode.split("-");
    // Bölme işlemi sonucunda pazaryeri[1] olup olmadığını kontrol ediyoruz
    if (pazaryeri.length > 1) {
      let firstChar = pazaryeri[1][0].toLowerCase(); // Küçük harfe çeviriyoruz

      if (firstChar === "s") {
        return "e-ticaret";
      } else if (firstChar === "n") {
        return "n11";
      } else if (firstChar === "t") {
        return "trendyol";
      } else if (firstChar === "h") {
        return "hb";
      }
    }

    // Şartlar sağlanmazsa null döndür
    return null;
  };

  const getSlugify = (title) => {
    return slugify(title, { lower: true });
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ürün Resmi</TableCell>
            <TableCell align="center">Stok Kodu</TableCell>
            <TableCell align="center">Ürün Adı</TableCell>
            <TableCell align="center">Stok</TableCell>
            <TableCell align="center">Ürün Satış Fiyatı</TableCell>
            <TableCell align="center">Ürün İndirimli Fiyatı</TableCell>
            <TableCell align="center">Ürün Linki</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(singlePro) &&
            singlePro.slice(1).map((item, index) => (
              <TableRow
                key={index + 1} // Index + 1 kullanıyoruz çünkü slice(1) ile index 0'dan başlayacak
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <img
                    src={item.pictures[0].picture}
                    width={50}
                    height={50}
                    alt=""
                  />
                </TableCell>
                <TableCell align="center">{item.productCode}</TableCell>
                <TableCell align="center">{item.name}</TableCell>
                <TableCell align="center">{item.quantity}</TableCell>
                <TableCell align="center">
                  {getProductSalesPrice(item.productCode) === "e-ticaret"
                    ? item.e_ticaret_satis_fiyati
                    : getProductSalesPrice(item.productCode) === "n11"
                    ? item.n11_satis_fiyati
                    : getProductSalesPrice(item.productCode) === "trendyol"
                    ? item.trendyol_sale_price
                    : getProductSalesPrice(item.productCode) === "hb"
                    ? item.hb_sale_price
                    : null}
                </TableCell>
                <TableCell align="center">
                  {getProductSalesPrice(item.productCode) === "e-ticaret"
                    ? item.e_ticaret_indirimli_fiyati
                    : getProductSalesPrice(item.productCode) === "n11"
                    ? item.n11_indirimli_fiyati
                    : getProductSalesPrice(item.productCode) === "trendyol"
                    ? item.trendyol_list_price
                    : getProductSalesPrice(item.productCode) === "hb"
                    ? item.hb_list_price
                    : null}
                </TableCell>
                <TableCell align="center">
                  <a
                    href={`https://www.koodmax.com/arama?urunAra=${item.productCode}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
// https://www.trendyol.com/sr?q=3mm%20Led%20Seti%20500%20Adet&qt=3mm%20Led%20Seti%20500%20Adet&st=3mm%20Led%20Seti%20500%20Adet&mid=675183&os=1