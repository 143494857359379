import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Topbar from "./layout/Topbar";
import Sidebars from "./layout/Sidebars";
import Dashboard from "./page/Dashboard/Dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Personal from "./page/Personal/Personal";
import Login from "./page/Login/Login";
import Account from "./page/Account/Account";
import Brands from "./page/Brands/Brands";
import Suppliers from "./page/Suppliers/Suppliers";
import Categories from "./page/Categories/Categories";
import ProductLayout from "./page/Product";
import Product from "./page/Product/Product";
import ProductPage from "./page/Product/ProductPage";
import { getToken } from "./utils/getToken";
import Orders from "./page/Orders/Orders";
import ScrollToTopButton from "./layout/ScrollToTopButton";
import OrdersLayout from "./page/Orders";
import OrderPage from "./page/Orders/OrderPage";
import Message from "./page/Message/Message";
import MessageLayout from "./page/Message";
import MessagePage from "./page/Message/MessagePage";

function App() {
  const [theme, colorMode] = useMode();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (!getToken()) setIsLogin(false);
    else setIsLogin(true);
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {!isLogin ? (
          <Login setIsLogin={setIsLogin} />
        ) : (
          <>
            <CssBaseline />
            <div className="app">
              <Sidebars />

              <main className="content">
                <Topbar setIsLogin={setIsLogin} />
                {/* <Dashboard /> */}

                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/users" element={<Personal />} />
                  {/* Markalar */}
                  <Route path="/brands" element={<Brands />} />
                  {/* Tedarikçiler */}
                  <Route path="/suppliers" element={<Suppliers />} />
                  {/* Kategoriler */}
                  <Route path="/categories" element={<Categories />} />
                  {/* Siparişler */}
                  <Route path="/orders" element={<OrdersLayout />} >
                    <Route index={true} element={<Orders/>} />
                    <Route path=":order_number" element={<OrderPage />} />
                  </Route>
                  {/* Ürünler */}
                  <Route path="/product" element={<ProductLayout />}>
                    <Route index={true} element={<Product />} />
                    {/* Ürün Tekil Sayfası */}
                    <Route path=":productCode" element={<ProductPage />} />
                  </Route>
                  <Route path="/message" element={<MessageLayout/>}>
                  <Route index={true} element={<Message/>}/>
                  <Route path=":questionId" element={<MessagePage/>}/>
                  </Route>
                </Routes>
                <ScrollToTopButton/>
              </main>
            </div>
          </>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
