import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import { TiStarburst } from "react-icons/ti";
import { Link } from "react-router-dom";

export default function MessageCard({ quest }) {

  
  return (
    <Card sx={{ width: "100%", mb: 3, boxShadow: 2, position: "relative" }}>
      {!quest.isRead ? 
      <Box sx={{position : "absolute", top : 0, right : "6px"}}>
        <TiStarburst color="primary" size={35}/>
      </Box> : null}
      <CardActionArea>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Resim kısmı */}
          {quest.images ? (
            <CardMedia
              component="img"
              sx={{ width: 140, height : 190 }}
              image={quest.images}
              alt={quest.questionSubject}
            />
          ) : null}
          {/* İçerik kısmı */}
          <CardContent sx={{ flex: 1 }}>
          <Link style={{textDecoration : 'none'}} to={`/message/${quest.id}`}>
            <Typography variant="h5" component="div">
              {quest.questionSubject}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {quest.question}
            </Typography>
            </Link>
          </CardContent>
        </Box>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <Link to={`/product/${quest.productCode}`}>
          {quest.productCode}
          </Link>
        </Button>
      </CardActions>
    </Card>
  );
}
