import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../../utils/getToken";

const initialState = {
    result : [],
    singleQuest : [],
    unreadMessage : [],
    notificationLoading : false,
    n11Loading : false,
    n11Error : null
}

export const getAllProductQuestion = createAsyncThunk('getAllProductQuestion', async () => {
    const token = getToken();

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/n11/get-all-question-db`, {
        method : "GET",
        headers : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    })

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      return response.json();
})

export const getSingleProductQuestion = createAsyncThunk('getSingleProductQuestion', async (id) => {
    const token = getToken();

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/n11/get-single-question-db/${id}`, {
        method : "GET",
        headers : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    })

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      return response.json();

})

export const howManyUnreadMessage = createAsyncThunk('howManyUnreadMessage', async () => {
    //how-many-unread-message
    const token = getToken();

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/n11/how-many-unread-message`, {
        method : "GET",
        headers : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    })

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      return response.json();

})

const n11Slice = createSlice({
    name : 'n11',
    initialState,
    reducers : {},
    extraReducers : (builder) => {
        builder
        .addCase(getAllProductQuestion.pending, (state) => {
            state.n11Loading = true;
        })
        .addCase(getAllProductQuestion.fulfilled, (state, action) => {
            state.result = action.payload;
            state.n11Loading = false;
        })
        .addCase(getAllProductQuestion.rejected, (state, action) => {
            state.n11Error = action.payload;
            state.n11Loading = false;
        })
        .addCase(getSingleProductQuestion.pending, (state) => {
            state.n11Loading = true;
        })
        .addCase(getSingleProductQuestion.fulfilled, (state, action) => {
            state.singleQuest = action.payload;
            state.n11Loading = false;
        })
        .addCase(getSingleProductQuestion.rejected, (state, action) => {
            state.n11Error = action.payload;
            state.n11Loading = false;
        })
        .addCase(howManyUnreadMessage.pending , (state, action) => {
            state.notificationLoading = true;
        })
        .addCase(howManyUnreadMessage.fulfilled , (state, action) => {
            state.unreadMessage = action.payload;
            state.notificationLoading = false;
        })
    }
})

export default n11Slice.reducer;