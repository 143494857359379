//! ÜRÜNLER
import { Container, Fab, Typography } from "@mui/material";
import ProductTable from "../../component/Product/ProductTable";
import SearchArea from "../../component/Product/SearchArea";
import Paginations from "../../component/Product/Pagination";
import { useSelector } from "react-redux";
import ProductDialogWindow from "../../component/Product/ProductDialogWindow";

const Product = () => {
  const { product } = useSelector((state) => state.product);
  const { isQuery } = useSelector((state) => state.product);

  return (
    <Container fixed>
      <h3 style={{ textDecoration: "underline" }}>Ürün Listesi</h3>
      <div style={{ marginTop: 25 }}>
        <div
          style={{
            display: "flex",
            justifyContent : "end",
            marginTop: 5,
            marginBottom : 5
          }}
        >
          <div>
            <Typography>
              <ProductDialogWindow />
            </Typography>
          </div>
        </div>
      </div>

      <div>
         <SearchArea />
      </div>
        {!isQuery ?       product.totalProduct < 100 ? null : (
        <div className="d-flex justify-content-center mb-5">
          <Paginations count={parseInt(product.totalProduct)} />
        </div>
      ) : null}
      <ProductTable rows={product.productList} />
      {!isQuery ?       product.totalProduct < 100 ? null : (
        <div className="d-flex justify-content-center mb-5">
          <Paginations count={parseInt(product.totalProduct)} />
        </div>
      ) : null}
    
    </Container>
  );
};

export default Product;
