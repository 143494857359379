import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import ControlledComponent from "./ControlledComponent";
import { useDispatch } from "react-redux";
import { orderSearch } from "../../redux/odersSlice/ordersSlice";

export default function OrderSearch() {
  const [orderID, setOrderID] = useState(null);
  const [orderNo, setOrderNo] = useState(null);
  const [firma, setCompany] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  
  const selectCompany = (e) => {
    setCompany(e.target.value);
  };

  const handleOrderSearch = () => {
    if(orderID){
      dispatch(orderSearch({query : 'orderId', data : orderID}))
      console.log("orderID : " , orderID)    
    }else if(orderNo){
      dispatch(orderSearch({query : 'orderNo', data : orderNo}))
      console.log("orderID : " , orderID)    

    }else if(firma){
      dispatch(orderSearch({query : 'firma', data : firma}))
      console.log("firma : " , firma)    

    }else if(startDate || endDate){
      dispatch(orderSearch({query : 'date', data : {startDate, endDate}}))
      console.log("startDate : " , startDate)    

    }
    // else{
    //   dispatch(getAllOrders())
    // }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Sipariş ID :
            </div>
            <div style={{ width: "100%" }}>
              <Stack spacing={1}>
                <TextField
                  onChange={(e) => setOrderID(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Sipariş No :
            </div>
            <div style={{ width: "100%" }}>
              <Stack >
                <TextField
                  onChange={(e) => setOrderNo(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className=" d-flex align-items-center">
            <div style={{ width: 100 }} className="me-2 text-center">
              Firma :
            </div>
            <div style={{ width: "100%" }}>
              <FormControl sx={{ width: "100%" }}>
              <Select value={firma} onChange={selectCompany}>
                <MenuItem value="All">==Tümü==</MenuItem>
                <MenuItem value="ETicaret">ETicaret</MenuItem>
                <MenuItem value="HepsiBurada">HepsiBurada</MenuItem>
                <MenuItem value="Trendyol">Trendyol</MenuItem>
                <MenuItem value="N11">N11</MenuItem>
              </Select>
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
      <div className="d-flex align-items-center">
        <div style={{ width: 100 }} className="me-2 text-center">
          Tarih :
        </div>
        <div style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1, marginRight: 8 }}>
            <ControlledComponent
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </div>
          <span style={{ marginRight: 8 }}>-</span>
          <div style={{ flex: 1 }}>
            <ControlledComponent
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </div>
        </div>
      </div>
    </Grid>
      </Grid>
      <Grid sx={{ marginY: 2 }} container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} md={4}>
          <Button
            sx={{ backgroundColor: "#1565C0" }}
            fullWidth
            size="large"
            variant="contained"
            onClick={() => handleOrderSearch()}
          >
            Ara
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
