import { Box, Skeleton } from "@mui/material";

const Skeletons = () => {
return(
    <Box>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
    </Box>
)
}

export default Skeletons;