import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Container, Grid, Typography,IconButton, Skeleton  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetailsForm } from "../../component/Product/ProductDetailsForm";
import CkEditor from "../../component/Product/CkEditor";
import AddPictureModal from "../../component/Product/AddPictureModal";
import AddIcon from "@mui/icons-material/Add";
import { singleProduct } from "../../redux/productSlice/productSlice";
import CircularIndeterminate from "../../layout/CircularIndeterminate";
import PacketProductTable from "../../component/Product/PacketProductTable";
import MemoryIcon from '@mui/icons-material/Memory';
import { clearAiResult, questionAi } from "../../redux/AiSlice/aiSlice";
const ProductPage = () => {
  const dispatch = useDispatch();
  const { singlePro, isLoading, error } = useSelector((state) => state.product);
  const { result, aiLoading, aiError} = useSelector((state) => state.ai);
  const { productCode } = useParams();
  const [editorData, setEditorData] = useState("");
  const [openPictureModal, setOpenPictureModal] = useState(false);

  useEffect(() => {
    if (productCode) {
      dispatch(singleProduct(productCode));
    }
    // if(result)
      dispatch(clearAiResult());

  }, [dispatch]);

  const handleAiQuestion = (productCode, name) => {
    dispatch(questionAi({productCode, name}))
  }

  useEffect(() => {
    if (
      Array.isArray(singlePro) &&
      singlePro.length > 0 &&
      singlePro[0].description
    ) {
      setEditorData(singlePro[0].description);
    } else {
      setEditorData("Ürün Açıklamasına Ulaşılamadı");
    }
  }, [singlePro]);

  if (isLoading) {
    return (
      <Container fixed>
        <CircularIndeterminate />
      </Container>
    );
  }

  if (error) {
    return (
      <Container fixed>
        <Typography variant="h5" color="error">
          Hata: {error}
        </Typography>
      </Container>
    );
  }
  if (!singlePro || singlePro.length === 0) {
    return (
      <Container fixed>
        <Typography variant="h3" color="red">
          Ürün Bulunamadı{" "}
          <Link style={{ textDecoration: "none" }} to="/product">
            Product Sayfasına Geri Dön
          </Link>
        </Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1 }}>
        {/* Ürün Güncelleme alanı */}
        <Box>
          <ProductDetailsForm pro={singlePro[0]} editorData={editorData} />
        </Box>
        {/* resim */}
        <Box sx={{ boxShadow: 3, mt: 7, borderRadius: 5 }}>
          <Grid container spacing={2}>
            {singlePro?.[0]?.pictures?.length > 0 ? (
              singlePro[0].pictures.map((data, i) => (
                <Grid key={i} item sx={{ mb: 1 }} lg={2} md={4} sm={6}>
                  <Box ml={1}>
                    <img src={data.picture} width={100} height={100} alt="" />
                  </Box>
                </Grid>
              ))
            ) : (
              <p>Resimler bulunamadı</p>
            )}
            <Grid item lg={1}>
              <Box
                sx={{
                  mt: 2,
                  width: "100%",
                  height: "75px",
                  // border: "2px dashed #1e90ff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                  "&:hover": {
                    backgroundColor: "#e0f7ff",
                    border: "2px solid #1e90ff",
                  },
                }}
                onClick={() => setOpenPictureModal(true)}
              >
                <div className="plus">
                  <AddIcon fontSize="large" />
                </div>
              </Box>
            </Grid>
          </Grid>
          <AddPictureModal
            pro={singlePro[0]}
            setOpenPictureModal={setOpenPictureModal}
            openPictureModal={openPictureModal}
          />
        </Box>
        {/* Paket ürünler */}
        <Box sx={{ mt: 3, borderRadius: 5 }}>
          <Typography variant="h4" sx={{ my: 1 }}>
            Paket Ürünler
          </Typography>
          <PacketProductTable singlePro={singlePro} />
        </Box>
      </Box>
      <Box sx={{ mt: 3, borderRadius: 5 }} >
        <Button onClick={() => handleAiQuestion(singlePro[0].productCode, singlePro[0].name)} startIcon={<MemoryIcon/>} >Yapay Zeka İle çıklama Oluştur</Button >
        {aiLoading ? 
            <Box sx={{ width: 300 }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box> : null }
          {result ? <>
          {result.content}
          </> : null}
      </Box>
      <Box sx={{ marginTop: 5 }}>
        {editorData && (
          <CkEditor editorData={  result.content ? result.content.slice(7, -3) : editorData} setEditorData={setEditorData} />
        )}
      </Box>
    </Container>
  );
};

export default ProductPage;
