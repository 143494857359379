import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleProductQuestion } from "../../redux/N11Slice/n11Slice";
import Skeletons from "../../component/Loading/Skeletons";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const MessagePage = () => {
  const textareaRef = useRef(null);
  const { questionId } = useParams();
  const [answer, setAnsver] = useState("");
  const dispatch = useDispatch();
  const { singleQuest, n11Loading, n11Error } = useSelector(
    (state) => state.n11
  );

 const handleChange = (event) => {
    setAnsver(event.target.value);

        // Yüksekliği dinamik olarak ayarlayın
        textareaRef.current.style.height = '150px'; // İlk önce yüksekliği sıfırla
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }

  const sendAnswer = () => {
   console.log(answer)
  }

  useEffect(() => {
    dispatch(getSingleProductQuestion(questionId));
  }, [dispatch]);

  if (n11Loading) {
    return (
      <Container>
        <Skeletons />
      </Container>
    );
  }

  return (
    <Container>
      <Box>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <Box
              sx={{
                my: 4,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={6} lg={3}>
                  <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
                    {singleQuest.id}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
                    {singleQuest.productCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
                    {singleQuest.productTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ my: 4 }}>
              <Typography variant="h3" component="div">
                {bull} {singleQuest.questionSubject}
              </Typography>
            </Box>
            <Box>
              {singleQuest.images ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={4}>
                    <img src={singleQuest.images} width={"100%"} />
                  </Grid>
                  <Grid item xs={6} md={8} lg={8}>
                    <Typography fontSize={16} variant="overline">
                      {singleQuest.question}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography fontSize={16} variant="overline">
                  {singleQuest.question}
                </Typography>
              )}
            </Box>
            <hr />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                my: 2,
              }}
            >
              <Box>{singleQuest.questionDate}</Box>
              <Box>{singleQuest.fullName}</Box>
              <Box>{singleQuest.email}</Box>
            </Box>
          </CardContent>
          <CardActions>
            <Accordion sx={{ width: "100%", bgcolor: "#F3F3F3" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Cevap Vermek İçin Tıklayınız...
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div class="form-floating">
                    <textarea
                      ref={textareaRef}
                      class="form-control"
                      placeholder="Cevabınızı Buraya Yazın"
                      id="floatingTextarea"
                      value={answer}
                      onChange={handleChange}
                      style={{ overflow: 'hidden', resize: 'none', height : "150px" }} // Elle yeniden boyutlandırmayı devre dışı bırakır
                    ></textarea>
                    <label for="floatingTextarea">Cevap</label>
                  </div>
                  <div className="mt-2">
                    <button onClick={sendAnswer} type="button" class="btn btn-outline-success w-100">
                      Gönder
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default MessagePage;
