import {
  Sidebar,
  Menu,
  MenuItem,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IoStorefrontOutline } from "react-icons/io5";
import { MdEmojiTransportation } from "react-icons/md";
import { TbCategoryMinus } from "react-icons/tb";
import { RiHome3Line } from "react-icons/ri";
import { MdProductionQuantityLimits } from "react-icons/md";
import { LuUsers2 } from "react-icons/lu";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineFindInPage } from "react-icons/md";
import { useSelector } from "react-redux";
import { MdOutlineMessage } from "react-icons/md";

const Sidebars = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [acKapa, setAcKapa] = useState(false);
  const [mee, setMee] = useState(null);
  const me = useSelector((state) => state.user.me);
  const toggleDashboard = () => {
    acKapa ? setAcKapa(false) : setAcKapa(true);
  };

  useEffect(() => {
    const storedMee = JSON.parse(localStorage.getItem("me"));
    setMee(storedMee);
  }, []);

  return (
    <Sidebar
      collapsed={acKapa}
      transitionDuration={500}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: `${colors.primary[400]}`,
          color: `${colors.grey[400]}`,
          position: "fixed" /* Sidebar'ı sabitle */,
          top: 0 /* Sayfanın üst kısmına yerleştir */,
          bottom: 0 /* Sayfanın alt kısmına kadar uzanmasını sağla */,
          left: 0 /* Sayfanın sol kısmına yerleştir */,
          overflowy:
            "auto" /* Eğer sidebar yüksekliği sayfa yüksekliğinden büyükse, scrollbar ekle */,
          paddingLeft: 15,
          paddingRight: 15,
        },
        ["." + menuClasses.subMenuContent]: {
          backgroundColor: `${colors.primary[400]}`,
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={toggleDashboard}
          aria-label="fingerprint"
          color="secondary"
        >
          {acKapa ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          marginY={2}
          display={acKapa ? "none" : "flex"}
          // sx={{ border: "2px solid grey" }}
        >
          <Typography variant="h2" component="h2">
            KoodMax Entegra
          </Typography>
        </Box>
      </div>
      <Menu>
        <MenuItem
          component={<Link to="/" />}
          icon={!acKapa ? <RiHome3Line /> : ""}
        >
          {!acKapa ? <div className=""> Ana Sayfa </div> : <RiHome3Line />}
        </MenuItem>
        <MenuItem
          component={<Link to="/product" />}
          icon={!acKapa ? <MdProductionQuantityLimits /> : ""}
        >
          {!acKapa ? (
            <div className=""> Ürünler </div>
          ) : (
            <MdProductionQuantityLimits />
          )}
        </MenuItem>
        <MenuItem
          component={<Link to="/orders" />}
          icon={!acKapa ? <MdOutlineFindInPage /> : ""}
        >
          {!acKapa ? (
            <div className=""> Siparişler </div>
          ) : (
            <MdOutlineFindInPage />
          )}
        </MenuItem>
        <MenuItem
          component={<Link to="/message" />}
          icon={!acKapa ? <MdOutlineMessage/> : ""}
        >
          {!acKapa ? (
            <div className=""> Mesajlar </div>
          ) : (
            <MdOutlineMessage/>
          )}
        </MenuItem>

        <MenuItem
          component={<Link to="/brands" />}
          icon={!acKapa ? <IoStorefrontOutline /> : ""}
        >
          {!acKapa ? (
            <div className=""> Müşteriler </div>
          ) : (
            <IoStorefrontOutline></IoStorefrontOutline>
          )}
        </MenuItem>
        <MenuItem
          component={<Link to="/suppliers" />}
          icon={!acKapa ? <MdEmojiTransportation /> : ""}
        >
          {!acKapa ? (
            <div className=""> Tedarikciler </div>
          ) : (
            <MdEmojiTransportation />
          )}
        </MenuItem>
        <MenuItem
          component={<Link to="/categories" />}
          icon={!acKapa ? <TbCategoryMinus /> : ""}
        >
          {!acKapa ? (
            <div className=""> Kategoriler </div>
          ) : (
            <TbCategoryMinus></TbCategoryMinus>
          )}
        </MenuItem>
        <MenuItem
          component={<Link to="/users" />}
          icon={!acKapa ? <LuUsers2 /> : ""}
          style={{ ":hover": { backgroundColor: "#f0f0f0" } }}
        >
          {!acKapa ? <div className=""> Kullanıcılar </div> : <LuUsers2 />}
        </MenuItem>
        <MenuItem
          component={<Link to="/account" />}
          icon={!acKapa ? <LuUser2 /> : ""}
        >
          {!acKapa ? <div className=""> Hesabım </div> : <LuUser2 />}
        </MenuItem>
      </Menu>
      {!acKapa ? (
        <div
          style={{ padding: "0 24px", marginBottom: "8px", marginTop: "32px" }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            style={{ opacity: 0.7, letterSpacing: "0.5px" }}
          >
            Kullanıcı <br />
            {mee ? mee.fullName : me.fullName}
          </Typography>
        </div>
      ) : null}
    </Sidebar>
  );
};

export default Sidebars;
