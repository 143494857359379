import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getToken } from "../../utils/getToken"


const initialState = {
    result : [],
    aiLoading : false,
    aiError : null
}

export const questionAi = createAsyncThunk('questionAi', async (data)=> {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/ai`,
        {
            method : "POST",
            headers : {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body : JSON.stringify(data)
        }
    )
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      return response.json();
})


const aiSlice = createSlice({
    name : 'ai',
    initialState,
    reducers : {
        clearAiResult: (state) => {
            state.result = [];
          },    
    },
    extraReducers : (builder) => {
        builder
        .addCase(questionAi.pending, (state) => {
            state.aiLoading = true;
        })
        .addCase(questionAi.fulfilled, (state, action) => {
            state.aiLoading = false;
            state.result = action.payload;
        })
        .addCase(questionAi.rejected, (state, action) => {
            state.aiError = action.payload
            state.aiLoading = false
        })
    }
})
export const {clearAiResult} = aiSlice.actions;
export default aiSlice.reducer;