import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductQuestion } from "../../redux/N11Slice/n11Slice";
import { Box, Container } from "@mui/material";
import Skeletons from "../Loading/Skeletons";
import MessageCard from "./MessageCard";
import ScrollReveal from 'scrollreveal';

const MessageContainer = () => {
    const dispatch = useDispatch();
    const { result, n11Loading, n11Error } = useSelector(state => state.n11);

    useEffect(() => {
        dispatch(getAllProductQuestion());
    }, [dispatch]);

    useEffect(() => {
        // Tüm bileşenler render edildikten sonra ScrollReveal'i çalıştırmak için bir süre bekleyin
        setTimeout(() => {
            ScrollReveal().reveal('.my-element', {
                duration: 1000,
                origin: 'bottom',
                distance: '20px',
                reset: false // Animasyonu bir kez çalıştırmak için
            });
        }, 100); // 100 ms'lik bir gecikme koyabilirsiniz, gerektiğinde artırılabilir
    }, [result]);

    if (n11Loading) {
        return <Skeletons />;
    }

    if (n11Error) {
        return <>Error: {n11Error}</>;
    }

    return (
        <Container>
            {result ? result.map((question, i) => (
                <Box className="my-element" key={i}>
                    <MessageCard quest={question} />
                </Box>
            )) : <div>Result Yok</div>}
        </Container>
    );
};

export default MessageContainer;
