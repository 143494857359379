import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getAllOrders } from "../../redux/odersSlice/ordersSlice";
import CircularIndeterminate from "../../layout/CircularIndeterminate";
import OrderPagination from "./OrderPagination";
// import orders from "../../order.json";
import OrderDetailTable from "./orderDetailTable";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const orderStatusCode = [
  { code: 1, description: "Yeni Sipariş", color: "#0000FF" },
  { code: 2, description: "Onaylandı", color: "#008000" },
  { code: 3, description: "Kargolandı", color: "#FFA500" },
  { code: 4, description: "Tamamlandı", color: "#006400" },
  { code: 5, description: "Hatalı", color: "#FF0000" },
  { code: 6, description: "Ön Sipariş", color: "#800080" },
  { code: 7, description: "Kargoya Hazır", color: "#FFA07A" },
  { code: 8, description: "Ödeme Alındı", color: "#FFD700" },
  { code: 9, description: "İade-İptal", color: "#8B0000" },
  { code: 10, description: "Onay Bekliyor", color: "#ADD8E6" },
  { code: 11, description: "İadesi Onaylanan", color: "#FFC0CB" },
  { code: 12, description: "Hazırlanıyor", color: "#90EE90" },
  { code: 13, description: "Tedarik Sürecinde", color: "#00008B" },
  { code: 14, description: "Tedarik Edilemedi", color: "#A9A9A9" },
  { code: 15, description: "Harici Depodan Gönderilecek", color: "#4B0082" },
];

const OrderTable = () => {
  const [select, setSelect] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const { orders, isLoading, error, pagination } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  if (isLoading) {
    return <CircularIndeterminate />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orders) {
    return null;
  }

  return (
    <div>
      {/* Pagination area */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <OrderPagination sx={{ align: "center" }} />
      </Box>
      {/* Table area */}
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="order table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>#</TableCell>
                <TableCell>İşlemler</TableCell>
                <TableCell>Sipariş Bilgileri</TableCell>
                <TableCell>Ürün</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.orders.map((row, i) => (
                <TableRow
                  key={i}
                  onClick={() => setSelect(i)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    bgcolor: select === i ? "#F2F0F0" : null,
                    boxShadow: select === i ? 5 : 0,
                  }}
                >
                  <TableCell
                    sx={{
                      color: "#ffffff",
                      maxWidth: "1px",
                      bgcolor:
                        orderStatusCode.find(
                          (color) => color.code === parseInt(row.status)
                        )?.color || null,
                      writingMode: "vertical-rl", // Metni dikey yapar (sağdan sola yukarıdan aşağıya)
                      textAlign: "center", // Metni ortalamak için
                      transform: "rotate(180deg)", // Yazıyı ters çevirmek için, böylece normal okunur
                    }}
                    component="th"
                    scope="row"
                  >
                    {orderStatusCode.find(
                      (color) => color.code === parseInt(row.status)
                    )?.description || "No Status"}
                  </TableCell>
                  <TableCell>
                    <Checkbox color="primary" />
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box>
                        {row.entegration.toUpperCase() ===
                        "hepsiburada".toUpperCase()
                          ? "HB"
                          : row.entegration.toUpperCase()}{" "}
                      </Box>
                      <Box>
                        <IconButton
                          id="customized-button"
                          arial-controls={open ? "customized-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          // disablePortal
                          anchorEl={anchorEl}
                          id="customized-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          slotProps={{
                            paper: {
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={handleClose} disableRipple>
                            <CheckIcon />
                            Onaylandı Yap
                          </MenuItem>
                          <MenuItem onClick={handleClose} disableRipple>
                            <CloseIcon />
                            İptal Et
                          </MenuItem>
                          <Divider sx={{ bgcolor: "black", my: 2.5 }} />
                          <MenuItem onClick={handleClose} disableRipple>
                            <InfoIcon />
                            Daha Fazla Detay
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Box>
                  </TableCell>
                  {/* sipariş bilgileri */}
                  <TableCell>
                    <Box>
                      <ul>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Durumu :</span>{" "}
                          {row.status}{" "}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Sipariş Tarihi :
                          </span>{" "}
                          {row.datetime}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Sipariş No :
                          </span>{" "}
                          {row.no}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Kargo Firması :
                          </span>{" "}
                          {row.cargo_company.toUpperCase()}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Toplam Tutar :
                          </span>{" "}
                          {row.total}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Müşteri :</span>{" "}
                          {row.company.toUpperCase()}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Telefon No :
                          </span>{" "}
                          {row.mobil_phone}
                        </li>
                      </ul>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <OrderDetailTable orderProduct={row.order_product} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default OrderTable;
